











import { Vue } from "vue-property-decorator";
import { Component } from "vue-property-decorator";
import ConditionalBackLink from "@/views/legal/components/ConditionalBackLink.vue";
import Imprint_DE from "@/views/legal/i18n/Imprint_DE.vue";
import Imprint_EN from "@/views/legal/i18n/Imprint_EN.vue";

@Component({
  components: { ConditionalBackLink, Imprint_EN, Imprint_DE },
})
export default class Imprint extends Vue {}
